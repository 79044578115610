import axios from "axios";
// Next we make an 'instance' of it
const AxiosConfig = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_BASEURL,
  // baseURL: "http://192.168.1.9:8080",
});

/* ------------------- create a interceptors of axios ------------------- */
AxiosConfig.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosConfig;
