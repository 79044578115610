import { useEffect, useState } from "react";
import { Carousel, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Excerpt } from "../../../utils";

const BrandSection = () => {
  const [state, setState] = useState([]);
  const history = useHistory();

  const fetchAllBrands = async () => {
    try {
      /* ------------------------- getting banner from db ------------------------- */
      const userBanner = await AxiosConfig.get(`/kreesa/public/brands`);
      setState(userBanner.data);
    } catch (err) {
      toast.error(err?.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const searchDataItem = (id) => {

    const userType = localStorage.getItem("auth") === "ROLE_RETAILER" ||
        localStorage.getItem("auth") === "ROLE_WHOLESALER"
          ? "wholesale"
          : "retail"
    AxiosConfig.get(`/kreesa/customer/brand/${userType}/view-all-products/${id}`).then((res) => {
      history.push({
            pathname: localStorage.getItem("auth") === "ROLE_RETAILER" ||
            localStorage.getItem("auth") === "ROLE_WHOLESALER"
              ? "/seller/search-page"
              : "/user/search-page",
            search: `brand=${id}`,
            state: res.data,
          });
    });
  };

  return (
    <section
      className="w-100 px-lg-6 mt-1 mb-4 py-2"
      //   className="d-flex justify-content-between p-1 py-5 f-section footer__linear__gradient"
      // style={{ backgroundColor: "#6351ce" }}
    >
      <h4>Our Brands</h4>
      <Stack direction="horizontal" gap={2} className="w-100 d-flex pt-3">
        {state?.map((brand) => (
          <div
            className="text-center m-3"
            key={brand?.id}
            style={{
              width: 160,
            }}
          >
            <Link onClick={()=> searchDataItem(brand.id)}>
              <img
                className={`card-img-top p-2 img-fluid w-100`}
                alt={brand.imageName}
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${brand.id}/${brand?.image}`}
                srcSet={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${brand.id}/${brand?.image}`}
                style={{
                  aspectRatio: "4/3",
                  objectFit: "contains",
                }}
              />
            </Link>

            <div title={brand?.brandName}>
              <Link onClick={()=> searchDataItem(brand.id)}>
                <h6 className="text-capitalize">
                  {Excerpt(brand?.brandName, 15)}
                </h6>
              </Link>
            </div>
          </div>
        ))}
      </Stack>
    </section>
  );
};

export default BrandSection;
