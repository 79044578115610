import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../../ButtonLoaderHook";
import Spinner from "../../Spinner";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const validationSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand name is required"),
});

function EditBrandForm() {
  const { bId } = useParams();
  const history = useHistory();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Brand",
    "Loading..."
  );
  const [image, setImage] = useState({ preview: "", raw: "" });
  const fileTypes = ["JPG", "PNG", "JPEG", "MP4"];
  //
  const handleImageChange = (file) => {
    setImage({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };


  const getSingleBrand = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      /* ------------------------- getting banner from db ------------------------- */
      const brandRes = await AxiosConfig.get(
        `/kreesa/admin/brand/get-brand-by-id/${bId}`,
        config
      );
      setState(brandRes.data);
      setLoading(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };

  /* --------------------------------- submit --------------------------------- */
  const onSubmitHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append(
        "description",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      if (values.file !== null) {
        data.append("file", values.file);
      }
      data.append("id", bId);
      data.append("brandName", values.brandName);
      const res = await AxiosConfig.post(
        "/kreesa/admin/brand/update-brand",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      history.push("/admin/brand");
      if (res) {
        toast.success("Brand updated succesfully !");
        setButtonLoader(false);
      }
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    getSingleBrand();
  }, [bId]);

    /* ----------------------------- for wysiwyg editor ---------------------------- */
    const onEditorStateChange = (description) => {
        setDescription(description);
      };
    
      useEffect(() => {
        if (state.description !== undefined) {
          setDescription(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(state?.description))
            )
          );
        }
      }, [state]);

  return !loading ? (
    <Formik
      initialValues={{
        file: null,
        brandName: state?.brandName,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Upload Brand Image</span>
              </label>
              <FileUploader
                handleChange={(file) => {
                  setFieldValue("file", file);
                  handleImageChange(file);
                }}
                name="file"
                types={fileTypes}
              />
              {errors.file && touched.file ? (
                <div style={{ color: "red" }}>{errors.file}</div>
              ) : null}
            </div>
            <div className="text-muted h5">
              Brand Image must be 512 x 512 (pixels) Dimension
            </div>
          </div>
          <div className="col mb-3">
            {image.preview && (
              <img src={image.preview} alt="dummy" height="100" />
            )}
          </div>

          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Brand Name</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="brandName"
                value={values.brandName}
                onChange={handleChange}
                placeholder="Enter brand name"
              />
              {errors.brandName && touched.brandName ? (
                <div style={{ color: "red" }}>{errors.brandName}</div>
              ) : null}
            </div>
          </div>

          <div className="col">
            <div className="mb-3 col-md-7">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Banner Description</span>
                <span className="text-info"> (optional)</span>
              </label>
              <Editor
                editorState={description}
                onEditorStateChange={onEditorStateChange}
                editorStyle={{
                  backgroundColor: "white",
                  minHeight: "150px",
                  padding: "10px 20px",
                }}
                editorClassName="mt-2 p-10 mx-auto"
              />
            </div>
          </div>
          <div className="mb-3 mt-4 col-md-5">
            <button
              type="submit"
              className="btn btn-success btn-lg btn-block UppyInput-Progress"
              ref={buttonLoader}
              //   disabled={!isValid}
            >
              Update Brand
            </button>
          </div>
        </Form>
      )}
    </Formik>
    // <div>
    //   <div className="col">
    //     <div className="mb-3 col-md-12">
    //       <label className="form-label" htmlFor="inputCity">
    //         <span className="font-weight-bold">Select Banner</span>
    //       </label>
    //       <FileUploader
    //         handleChange={handleChange}
    //         name="file"
    //         types={fileTypes}
    //       />
    //     </div>
    //   </div>
    //   <div className="col">
    //     {image.preview && (
    //       <img src={image.preview} alt="dummy" width="100" height="100" />
    //     )}
    //   </div>
    //   <div className="col">
    //     <div className="mb-3 col-md-8">
    //       <label className="form-label" htmlFor="inputCity">
    //         <span className="font-weight-bold">Banner Description</span>
    //         <span className="text-info"> (optional)</span>
    //       </label>
    //       <Editor
    //         editorState={description}
    //         onEditorStateChange={onEditorStateChange}
    //         editorStyle={{
    //           backgroundColor: "white",
    //           minHeight: "150px",
    //           padding: "10px 20px",
    //         }}
    //         editorClassName="mt-2 p-10 mx-auto"
    //       />
    //     </div>
    //   </div>
    //   <div className="mb-3 mt-4 col-md-8">
    //     <button
    //       id="catadd"
    //       type="submit"
    //       className="btn btn-success btn-lg btn-block"
    //       ref={buttonLoader}
    //       onClick={onSubmitHandler}
    //       disabled={!buttonLoader}
    //     >
    //       Add User Banner
    //     </button>
    //   </div>
    // </div>
  ): (
    <Spinner />
  );
}

export default EditBrandForm;
