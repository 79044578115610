import React from "react";
import Header from "./Header";
import Nav from "./Nav";
import Slider from "./Slider";
import ProductList from "./ProductList/index";
import Footer from "./Footer";
import Banner from "../../Widgets/AddBanner/Banner";
import BrandSection from "../User/UserFooter/BrandSection";

function Home() {
  document.title = "Kreesa | Home";

  return (
    <div
      style={{
        backgroundColor: "rgb(233 236 239)",
      }}
    >
      <div style={{ position: "sticky", top: 0, zIndex: 99999 }}>
        <Header />
        <Nav />
      </div>
      <Slider />
      <ProductList />
      <Banner />
      <BrandSection />
      <Footer />
    </div>
  );
}

export default Home;
