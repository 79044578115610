import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get 16 Retailer And Wholesaler Products in userpage */
export const getRetailerProducts = createAsyncThunk(
  "getRetailerProducts/users",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get 16 Retailer trending Products in userpage */
export const getRetailerTrendingProducts = createAsyncThunk(
  "getRetailerTrendingProducts",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/trending/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get 16 Retailer organic Products in userpage */
export const getRetailerOrganicProducts = createAsyncThunk(
  "getRetailerOrganicProducts/userpage",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/organic/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get 16 Retailer Kreesa Products in userpage */
export const getRetailerKreesaProducts = createAsyncThunk(
  "getRetailerKreesaProducts/userpage",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/kreesa/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get 16 Retailer Brand Products in userpage */
export const getRetailerBrandProducts = createAsyncThunk(
  "getRetailerBrandProducts/userpage",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/brand/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get all Retailer Brand Products in userpage */
export const getAllRetailerTrendingProducts = createAsyncThunk(
  "getAllRetailerTrendingProducts/retailer",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/trending/product/${type}/view-all-products`
    );
    return response.data;
  }
);
export const getAllRetailerFeatureProducts = createAsyncThunk(
  "getAllRetailerFeatureProducts/retailer",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/features/product/${type}/view-all-products`
    );
    return response.data;
  }
);
export const getAllRetailerOrganicProducts = createAsyncThunk(
  "getAllRetailerOrganicProducts/retailer",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/organic/product/${type}/view-all-products`
    );
    return response.data;
  }
);
export const getAllRetailerKreesaProducts = createAsyncThunk(
  "getAllRetailerKreesaProducts/retailer",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/kreesa/product/${type}/view-all-products`
    );
    return response.data;
  }
);
/* to get all Retailer Brand Products in userpage */
export const getAllRetailerBrandProducts = createAsyncThunk(
  "getAllRetailerBrandProducts/userpages",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/brand/product/${type}/view-all-products`
    );
    return response.data;
  }
);

/* to get all  flash Products in userpage */
export const getAllRetailerFlashSale = createAsyncThunk(
  "getFlashSaleUser/userpages/retail",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/flash/product/${type}/view-all-products`
    );
    return response.data;
  }
);

/* to get all 8 flash Products in userpage */
export const getRetailerFlashSale = createAsyncThunk(
  "getRetailerFlashSale/userpages/retail",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/flash/product/${type}/view-all`
    );
    return response.data;
  }
);
/* to get 16 Feature Products For user */
export const featureProductForUser = createAsyncThunk(
  "featureProductForUser",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/features/product/${type}/view-all`
    );
    return response.data;
  }
);

/* to get Single  Retailer And Wholesaler Products in userpage */
export const getSingleRetailProducts = createAsyncThunk(
  "getSingleRetailProducts",
  async (id) => {
    const response = await AxiosConfig.get(`/kreesa/customer/product/${id}`);
    return response.data;
  }
);

export const postSingleRetailCart = createAsyncThunk(
  "postSingleRetailCart",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/customer/cart/add`, data)
      .then((res) => {
        toast.success(data.productName + " added to Cart.");
        dispatch(viewAllRetailCart(data.userId));
      })
      .catch((err) => toast.error("An Error Occurred"));
  }
);

/* ------------------------------ view all cart ----------------------------- */
export const viewAllRetailCart = createAsyncThunk(
  "getViewAllRetailCart",
  async (userId) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/cart/view-all?userId=${userId}`
    );
    return response.data;
  }
);

/* ------------------------------- remove cart ------------------------------ */
export const removeCartRetailItem = createAsyncThunk(
  "removeCartRetailItem",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/cart/items/remove?cId=${data.cartId}`
    ).then(
      (res) =>
        data?.productName &&
        toast.info(data?.productName + " removed successfully")
    );
    dispatch(viewAllRetailCart(data.userId));
    return response.data;
  }
);

/* ------------------------------- update cart ------------------------------ */
export const updateCartRetailItem = createAsyncThunk(
  "updateCartRetailItem",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/customer/cart/update`,
      data
    );
    dispatch(viewAllRetailCart(data.userId));
    return response.data;
  }
);

/* ------------------------------- clear cart ------------------------------- */
export const clearCart = createAsyncThunk("clearCart", async () => {
  return;
});

/* --------------------------------- logout --------------------------------- */
export const logoutUser = createAsyncThunk("logoutRetail", async () => {
  return;
});

/* -------------------------- USER PROFILE ACTIONS -------------------------- */
export const getUserProfile = createAsyncThunk(
  "getUserProfile/usere",
  async (id, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/get-user-by-user-id/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  "updateProfile/userr",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/retailer/update-profile`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    dispatch(getUserProfile(localStorage.getItem("userId")));
    return response.data;
  }
);

export const searchItemRetail = createAsyncThunk(
  "searchItemRetail",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/search-product`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const homePageSearch = createAsyncThunk(
  "searchItemRetail",
  async (data, { dispatch }) => {
    return data;
  }
);

/* ------------------------------- user order ------------------------------- */
export const getUserOrder = createAsyncThunk(
  "searchItemRetail",
  async (id, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/retailer/order/view/consumer/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);
/* --------------------------------- carrer --------------------------------- */

export const getVacancies = createAsyncThunk("getUserCarrer", async () => {
  const { data } = await AxiosConfig.get(`/kreesa/public/vacancy`);
  return data;
});

/* ---------------------------- user notification --------------------------- */
export const getUserNotification = createAsyncThunk(
  "getUserNotification",
  async (id) => {
    const response = await AxiosConfig.get(
      localStorage.getItem("auth") === "ROLE_USER"
        ? `/kreesa/notification/${id}?limit=10`
        : `/kreesa/wholesaler/get-all-notification/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* ------------------------------ mark as read ------------------------------ */
export const userReadAllNotification = createAsyncThunk(
  "userReadAllNotification",
  async (id) => {
    const response = await AxiosConfig.get(
      localStorage.getItem("auth") === "ROLE_USER"
        ? `/kreesa/notification/${id}/mark-read/all`
        : `/kreesa/wholesaler/notification/${id}/mark-read/all`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* ---------------------------- Read Notification --------------------------- */
export const userReadNotification = createAsyncThunk(
  "userReadNotificationById",
  async (id) => {
    const response = await AxiosConfig.get(
      `/kreesa/notification/marks-as-read/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* ------------------------- get notification count ------------------------- */
export const getNotificationCountUser = createAsyncThunk(
  "getNotificationCountUser",
  async (id) => {
    const response = await AxiosConfig.get(
      localStorage.getItem("auth") === "ROLE_USER"
        ? `/kreesa/notification/${id}/count`
        : `/kreesa/wholesaler/get-total-number-of-notification/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* --------------------------------- Get user quotation --------------------------------- */
export const getUserQuotation = createAsyncThunk(
  "user/Quotation",
  async (userId, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/quotation/get-quotation-for-user/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);
// to get invoice for my quotation
export const getUserInvoice = createAsyncThunk(
  "user/getUserInvoice",
  async (quoteOrderId, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/image/quote-invoice/${quoteOrderId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* --------------------------------- Approve user quotation --------------------------------- */
export const approveUserQuotation = createAsyncThunk(
  "approveUser/Quotation",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/approve`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => toast.success("Quotation Approved!"))
      .catch((err) => toast.error("An Error Occurred"));
    dispatch(getUserQuotation(data.userId));
    return response.data;
  }
);

/* --------------------------------- Reject user quotation --------------------------------- */
export const rejectUserQuotation = createAsyncThunk(
  "rejectUserrQuotation",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/retail/quotation/decline/${data.id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => toast.success("Quotation removed successfully"))
      .catch((err) => toast.error("An error occurred"));
    dispatch(getUserQuotation(data.userId));
    return response.data;
  }
);

export const checkWishList = createAsyncThunk(
  "checkWishList",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/wish-list/check`, {
      ...data,
    });
    return response.data;
  }
);

/* ----------------------------- add to wishlist ---------------------------- */
export const addToWishlist = createAsyncThunk(
  "addToWishlistss",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/wish-list`, {
      ...data,
    });
    dispatch(getSingleRetailProducts(data.productId));
    dispatch(checkWishList({ ...data }));

    return response.data;
  }
);

// remove from wishlist
export const removeFromWishlist = createAsyncThunk(
  "removeFromWishlists",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/wish-list/delete/${data.id}/user/${data.userId}`
    );
    dispatch(getSingleRetailProducts(data.productId));
    dispatch(checkWishList({ ...data }));
    return response.data;
  }
);

// to get all wishlists for user
export const getAllWishlists = createAsyncThunk(
  "getAllWishlists",
  async (userId) => {
    const response = await AxiosConfig.get(
      `/kreesa/wish-list/${userId}`,
      config
    );
    return response.data;
  }
);

// to get all Faq
export const getUserFaq = createAsyncThunk("getFaqs", async () => {
  const response = await AxiosConfig.get(`/kreesa/public/faq`);
  return response.data;
});

// to get Blogs
export const getBlogs = createAsyncThunk("getBlogs", async () => {
  const response = await AxiosConfig.get(`/kreesa/public/blog/view`);
  return response.data;
});

// to get Blog
export const getBlogById = createAsyncThunk("getBlog", async (id) => {
  const response = await AxiosConfig.get(`/kreesa/public/blog/view/${id}`);
  const response1= await AxiosConfig.get(`/kreesa/public/blog/update/${id}`);
  return response.data;
});

// to get legal docs
export const getLegalDocsUser = createAsyncThunk(
  "getLegalDocsUser",
  async (type) => {
    const response = await AxiosConfig.get(`/kreesa/legal/${type}/public`);
    return response.data;
  }
);
