import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import makeAnimated from "react-select/animated";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import DatePicker from "react-datepicker";
import { useEffect } from "react";
import { getWholesalerReviewById } from "../../../Admin/Features/Action/ProductAction";
import { FieldArray, Form, Formik } from "formik";
import Spinner from "../../../Admin/Spinner";
import useButtonLoader from "../../../ButtonLoaderHook";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { getCategories } from "../../../../Common/Features/Action/CategoryAction/CategoryAction";

export const warrantyPeriodOptions = [
  { value: "1", label: "1 year" },
  { value: "2", label: "2 years" },
  { value: "3", label: "3 years" },
  { value: "4", label: "4 years" },
  { value: "5", label: "5 years" },
  { value: "6", label: "6 years" },
  { value: "7", label: "7 years" },
  { value: "8", label: "8 years" },
  { value: "9", label: "9 years" },
  { value: "10", label: "10 years" },
  { value: "11", label: "11 years" },
  { value: "12", label: "12 years" },
  { value: "13", label: "13 years" },
  { value: "14", label: "14 years" },
  { value: "15", label: "15 years" },
  { value: "16", label: "16 years" },
  { value: "17", label: "17 years" },
  { value: "18", label: "18 years" },
  { value: "19", label: "19 years" },
  { value: "20", label: "20 years" },
];

const EditProduct = ({ wholesaler = false }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [visibles, setVisibles] = useState(false);
  const [description, setDescription] = useState(null);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [hasVarient, setHasVarient] = useState(false);
  const [perishable, setPerishable] = useState(false);
  const [previousColor, setPreviousColor] = useState({});
  const [productImage, setProductImage] = useState([]);
  const [isReturnable, setIsReturnable] = useState([]);
  const [validQuantity, setValidQuantity] = useState(false);
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Product",
    "Loading..."
  );

  const [hasWarranty, setHasWarranty] = useState(false);

  const animatedComponents = makeAnimated();
  //

  const [brands, setBrands] = useState([]);

  const fetchAllBrands = async () => {
    try {
      /* ------------------------- getting banner from db ------------------------- */
      const res = await AxiosConfig.get(`/kreesa/public/brands`);
      setBrands(res.data);
    } catch (err) {
      toast.error(err?.response.data.message);
    }
  };

  const handleChoice = (event) => {
    // setProducts({
    //   ...products,
    //   [event.target.name]: event.target.value,
    // });
  };

  const colorNames = [
    { label: "Red", value: "Red" },
    { label: "Green", value: "Green" },
    { label: "Black", value: "Black" },
    { label: "Yellow", value: "Yellow" },
    { label: "Gray", value: "Gray" },
    { label: "Blue", value: "Blue" },
    { label: "Golden", value: "Golden" },
  ];
  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };

  /* -------------------------- UpdateProductHandler -------------------------- */
  const UpdateProductHandler = async (values) => {
    setButtonLoader(true);

    try {
      let data = new FormData();

      for (let i = 0; i < values.specification?.length; i++) {
        if (values.specification[i].image.length < 1) {
          toast.error("At least one product image is required");
          setButtonLoader(false);
          return;
        }
        if (
          values.specification[i].productQuantity <
          values.specification[i].minimumQuantity
        ) {
          toast.error("Minimum quantity must be less than quantity");
          setButtonLoader(false);
          return;
        }
        data.append(
          `specification[${i}].productQuantity`,
          values.specification[i].productQuantity
        );
        data.append(
          `specification[${i}].productThickness`,
          values.specification[i].productThickness
        );
        data.append(
          `specification[${i}].productWeight`,
          values.specification[i].product_weight
        );

        data.append(`specification[${i}].id`, values.specificationId[i]);

        data.append(
          `specification[${i}].productRate`,
          values.specification[i].productRate
        );
        data.append(
          `specification[${i}].productLength`,
          values.specification[i].productLength
        );
        data.append(
          `specification[${i}].productHeight`,
          values.specification[i].productHeight
        );
        data.append(
          `specification[${i}].productSize`,
          values.specification[i].productSize
        );
        data.append(
          `specification[${i}].productDiscount`,
          values.specification[i].productDiscount
        );

        data.append(
          `specification[${i}].returnable`,
          values.specification[i].returnable
        );
        if (values.specification[i].returnable === true) {
          data.append(
            `specification[${i}].return_days`,
            values.specification[i].returnDays
          );
        }
        if (
          localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          wholesaler === true
        ) {
          data.append(
            `specification[${i}].minimumQuantity`,
            values.specification[i].minimumQuantity
          );
        }
        if (hasVarient === true) {
          data.append(
            `specification[${i}].variantName`,
            values.specification[i].variantName
          );
        }

        for (let k = 0; k < values.specification[i].image.length; k++) {
          const imageId = values.specification[i].image[k].id;
          const matchingImage = ReviewById?.specification[i].image.find(
            (img) => img.id === imageId
          );
          if (
            values.specification[i].image[k].imageName !== undefined &&
            !matchingImage
          ) {
            data.append(
              `specification[${i}].imagePojo[${k}].image`,
              values.specification[i].image[k].imageName
            );
          }
          if (matchingImage) {
            data.append(
              `specification[${i}].imagePojo[${k}].id`,
              values.specification[i].image[k].id
            );
          }
        }

        // values.image.flat().forEach((image, k) => {
        //   const matchingImage = ReviewById?.specification[i].image.find(
        //     (reviewImage) => reviewImage.id === image.id
        //   );
        //   if (image.imageName !== undefined && !matchingImage) {
        //     data.append(
        //       `specification[${i}].imagePojo[${k}].image`,
        //       image.imageName
        //     );
        //   }
        //   if (matchingImage) {
        //     data.append(`specification[${i}].imagePojo[${k}].id`, image.id);
        //   }
        // });

        for (let cl = 0; cl < values.specification[i].color.length; cl++) {
          data.append(
            `specification[${i}].color[${cl}].colorName[]`,
            values.specification[i].color[cl].colorName
          );

          data.append(
            `specification[${i}].color[${cl}].id`,
            values.specification[i].color[cl].id
          );
        }
      }

      data.append("id", values.id);

      data.append("productName", values.productName);
      // data.append(
      //   "sellerType",
      //   localStorage.getItem("auth") === "ROLE_RETAILER"
      //     ? "Retail"
      //     : "Wholesale"
      // );
      data.append(
        "productDescription",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );

      if (perishable === true) {
        data.append("expiryDate", moment(expiryDate).format("YYYY-MM-DD"));
      }
      data.append("perishable", perishable);

      if (hasVarient === true) {
        data.append("hasVariant", hasVarient);
      }
      data.append("primaryCategoryId", values.primary);
      data.append("secondaryCategoryId", values.secondary);
      if (values.ternaryCategoryId !== null) {
        data.append("ternaryCategoryId", values.ternary);
      }
      data.append("hasWarranty", hasWarranty);
      if (hasWarranty === true) {
        data.append("warrantyType", values.warrantyType);
        data.append("warrantyPeriod", values.warrentyPeriod);
      }
      data.append("userId", localStorage.getItem("userId"));
      data.append("unit", values.unit);
      data.append("brandId", values.brand);

      const res = await AxiosConfig.post(
        `/kreesa/${
          localStorage.getItem("auth") === "ROLE_WHOLESALER"
            ? "wholesaler"
            : localStorage.getItem("auth") === "ROLE_RETAILER"
            ? "retailer"
            : "admin"
        }/product/update/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res && res.status === 200) {
        toast.success(`${values.productName} product update succesfully !`);
        history.goBack();
        setButtonLoader(false);
      }
      setButtonLoader(false);
    } catch (err) {
      console.log(err);
      setButtonLoader(false);
    }
  };
  /* -------------------------- UpdateProductHandler -------------------------- */

  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories } = useSelector((s) => s.Categories);
  const { ReviewById, loader } = useSelector((s) => s.ProductWholesalerReducer);

  useEffect(() => {
    if (ReviewById?.productDescription?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(ReviewById?.productDescription)
          )
        )
      );
      setPreviousColor({});
      const colorValue = ReviewById?.specification?.map((i) =>
        i.color.map((j) => {
          return {
            label: j.colorName,
            value: j.colorName,
            colorName: j.colorName,
            specId: i.id,
          };
        })
      );

      setPreviousColor(colorValue?.flat(Infinity));

      if (ReviewById.expiryDate) {
        setExpiryDate(new Date(ReviewById?.expiryDate));
      }
      if (ReviewById.hasVariant === true) {
        setHasVarient(true);
      }
      if (ReviewById.hasWarranty === true) {
        setHasWarranty(true);
      }
      if (ReviewById.isPerishable === true) {
        setPerishable(true);
      }
      if (ReviewById.specification?.map((i) => i.returnable).includes(true)) {
        setIsReturnable(true);
      }
    }
  }, [ReviewById]);

  //
  useEffect(() => {
    fetchAllBrands();
    dispatch(getWholesalerReviewById(id));
    dispatch(getCategories());
  }, [id, localStorage.getItem("userId")]);

  return !loader ? (
    <Formik
      enableReinitialize={true}
      initialValues={{
        specification: ReviewById?.specification,
        productName: ReviewById?.productName,
        primary: ReviewById?.primaryCategoryId ?? 0,
        secondary: ReviewById?.secondaryCategoryId ?? 0,
        ternary: ReviewById?.ternaryCategoryId ?? 0,
        brand: ReviewById?.brandId ?? 0,
        perishable: ReviewById.perishable,
        hasVariant: ReviewById.hasVariant,
        hasWarranty: ReviewById.hasWarranty,
        variantName: ReviewById?.specification,
        specificationId: ReviewById?.specification?.map((p) => p.id),
        returnable: ReviewById?.returnable,
        returnDays: ReviewById?.returnDays,
        warrantyType: ReviewById?.warrantyType,
        warrentyPeriod: ReviewById?.warrantyPeriod,
        id: ReviewById?.id,
        unit: ReviewById?.unit,
        image: ReviewById?.specification?.map((spec) => spec.image) || [],
        colorId: ReviewById?.specification?.map((p) =>
          p.color.map((c) => c.id)
        ),
      }}
      onSubmit={UpdateProductHandler}
    >
      {({ handleChange, handleSubmit, values, setFieldValue, errors }) => (
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="d-grid gap-3 border">
            <div className="card-header text-header0">What you are selling</div>
            <div className="card-body">
              <div className="row">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Product Name <span className="text-danger">*</span>
                </label>
                <div className="col-lg-6">
                  <input
                    type="text"
                    name="productName"
                    value={values.productName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter product name"
                  />
                </div>
              </div>
              <div className="form-group row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Primary Category<span className="text-danger">*</span>
                </label>
                <div className="col-lg-6">
                  <select
                    className="form-control"
                    name="primary"
                    onChange={handleChange}
                    value={values.primary}
                  >
                    <option value="">Select Primary Category ...</option>
                    {/* to get all Primary Categories */}
                    {Categories.primary?.map((p, i) => (
                      <option
                        key={i}
                        selected={values.primary === p.categoryName}
                        value={p.id}
                      >
                        {p.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Secondary Category <span className="text-danger">*</span>
                </label>
                <div className="col-lg-6">
                  <select
                    className="form-control"
                    name="secondary"
                    onChange={handleChange}
                    value={values.secondary}
                  >
                    <option value="">Select Secondary Category ...</option>
                    {/* to get all Secondary Categories */}
                    {Categories.secondary?.map((p, i) => (
                      <option
                        key={i}
                        value={p.id}
                        selected={values.secondary === p.secondaryCategoryName}
                      >
                        {p.secondaryCategoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Ternary Category
                </label>
                <div className="col-lg-6">
                  <select
                    className="form-control"
                    name="ternary"
                    onChange={handleChange}
                    value={values.ternary}
                  >
                    <option value="">Select Ternary Category ...</option>
                    {/* to get all Ternary Categories */}
                    {Categories.ternary?.map((p, i) => (
                      <option
                        key={i}
                        value={p.id}
                        selected={values.ternary === p.ternaryCategoryName}
                      >
                        {p.ternaryCategoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Brand
                </label>
                <div className="col-lg-6">
                  <select
                    className="form-control"
                    name="brand"
                    onChange={handleChange}
                    value={values.brand}
                  >
                    <option value="">Select Brand ...</option>
                    {/* to get all Ternary Categories */}
                    {brands.map((p, i) => (
                      <option key={i} value={p.id}>
                        {p.brandName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d-grid gap-3  border">
            <div className="card-header text-header0">Basic Information</div>
            <div className="card-header">
              <div className="form-group row">
                <label htmlFor="inputProduct" className="col-form-label">
                  Product's Short Description:
                  <span className="text-danger">*</span>
                </label>
                <div className="text-editor1 mt-2">
                  <Editor
                    name="description"
                    editorState={description}
                    onEditorStateChange={onEditorStateChange}
                    editorClassName="mt-2 p-10 mx-auto"
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row align-items-center">
                <label className="form-label col-md-3" htmlFor="unit">
                  Product Unit <span className="text-info">(Optional)</span>
                </label>
                <div className="col-md-6">
                  <input
                    type="text"
                    name="unit"
                    value={values.unit}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter the product Unit (eg. per unit, per kg ...)"
                  />
                </div>
              </div>
              <div className="form-group row mt-4">
                <label className="form-label" htmlFor="inputPassword4">
                  <input
                    type="checkbox"
                    className="form-check-input mr-2"
                    name="Perishable"
                    onClick={() => setPerishable(!perishable)}
                    checked={perishable ? "checked" : null}
                  />{" "}
                  Is Perishable ?
                </label>
                {perishable && (
                  <div className="form-group row mt-4">
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 col-form-label"
                    >
                      Expiry Date<span className="text-danger"> *</span>
                    </label>
                    <div className="col-lg-6">
                      <DatePicker
                        className="form-control"
                        name="expiryDate"
                        selected={expiryDate}
                        onChange={(date) => setExpiryDate(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group row mt-4">
                <div className="">
                  <input
                    type="checkbox"
                    className="form-check-input mr-2"
                    name="hasWarrenty"
                    onClick={() => {
                      setVisible(!visible);
                      setHasWarranty(!hasWarranty);
                    }}
                    checked={hasWarranty ? "checked" : null}
                    onChange={handleChoice}
                  />
                  <label id="search_checkbox_label" className="ml-2">
                    {" "}
                    Click If Product Have Warrenty{" "}
                  </label>
                </div>
                {hasWarranty && (
                  <div className="row mt-4">
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 col-form-label"
                    >
                      Warrenty Type: <span className="text-danger">*</span>
                    </label>

                    <div className="col-md-4">
                      <select
                        className="form-control mt-2"
                        name="warrantyType"
                        onChange={handleChange}
                        value={values.warrantyType}
                      >
                        <option>Select Warrenty Type</option>
                        <option value="Shop Warrenty">Shop Warrenty</option>
                        <option value="Brand Warrenty">Brand Warrenty</option>
                      </select>
                    </div>
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 mt-1 col-form-label"
                    >
                      Warrenty Time Period:{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        name="warrentyPeriod"
                        onChange={handleChange}
                        value={values.warrentyPeriod}
                      >
                        <option>Select Warrenty Year</option>
                        {warrantyPeriodOptions?.map((option, i) => (
                          <option key={i} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="fieldGroup">
            {/* <div
              className="row mr-4 mt-0"
              style={{ float: "right", width: "185px" }}
            >
              <span
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  const newSpecification = [...values.specification];
                  newSpecification.push({
                    id: newSpecification.length + 1,
                  });
                  setFieldValue(`specification`, newSpecification);
                }}
              >
                Add More Specification
              </span>
            </div> */}
            <div className="ml-4 mb-4">
              <input
                type="checkbox"
                className="form-check-input ml-2"
                name="hasVarient"
                checked={hasVarient ? "checked" : null}
                onClick={() => {
                  setVisibles(!visibles);
                  setHasVarient(!hasVarient);
                }}
              />
              <label id="search_checkbox_label" className="ml-2">
                {" "}
                Click If Product Have Variation{" "}
              </label>
            </div>
            {values?.specification?.map((i, index, arr) => (
              <div className="d-grid gap-3 border" key={index}>
                <div className="card-header text-header0">
                  Variations
                  {/* <div className="" style={{ float: "right" }}>
                    {values?.specification?.length > 1 ? (
                      index > 0 && (
                        <span
                          className="btn btn-danger"
                          style={{
                            width: "178px",
                            position: "relative",
                            left: "21px",
                            top: "7px",
                          }}
                          onClick={() => {
                            setFieldValue(
                              `specification`,
                              values?.specification?.filter(
                                (e) => e.id !== i.id
                              )
                            );
                          }}
                        >
                          Remove
                        </span>
                      )
                    ) : (
                      <span
                        className="btn btn-danger ml-2"
                        style={{ cursor: "not-allowed", display: "none" }}
                        disabled="disabled"
                      >
                        Remove
                      </span>
                    )}
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="form-group row mt-4">
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 col-form-label"
                    >
                      Varient: <span className="text-danger">*</span>
                    </label>

                    <div className="col-md-6" id="select_div">
                      <input
                        className="form-control"
                        id="varientName"
                        name="variantName"
                        onChange={(e) =>
                          setFieldValue(`specification[${index}]`, {
                            ...i,
                            variantName: e.target.value,
                          })
                        }
                        value={i.variantName}
                        disabled={hasVarient ? "" : "disabled"}
                        placeholder="Varient Name"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group row mt-4">
                      <label
                        htmlFor="inputProduct"
                        className="col-md-2 col-form-label"
                      >
                        Color:
                      </label>
                      <div className="col-md-6">
                        <CreatableSelect
                          className="Select"
                          name="colorName"
                          placeholder="Choose Color"
                          isMulti
                          onChange={(event) => {
                            const selectValue = event.map((eMap) => {
                              return {
                                label: eMap.value,
                                value: eMap.value,
                                colorName: eMap.value,
                                specId: i.id,
                              };
                            });

                            const finalValue = [
                              ...previousColor.filter(
                                (fi) => fi.specId !== i.id
                              ),
                              ...selectValue,
                            ];

                            setPreviousColor(finalValue);

                            setFieldValue(`specification[${index}]`, {
                              ...i,
                              color: selectValue,
                            });
                          }}
                          value={
                            previousColor?.length > 0
                              ? previousColor?.filter(
                                  (fData) => i.id === fData?.specId
                                )
                              : ""
                          }
                          options={colorNames}
                        />
                      </div>
                    </div>
                    {/* <div className="form-group row mt-4">
                      <label
                        htmlFor="inputProduct"
                        className="col-md-2 col-form-label"
                      >
                        Product's Images (4):{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <input
                          type="file"
                          multiple
                          className="form-control"
                          id="pimages"
                          name="images"
                          accept="image/png,image/jpg,image/jpeg"
                          // onChange={(event) =>
                          //   handleSpecificationChange(ind, event)
                          // }
                        />
                        <small>Accepted Formats: png, jpg & jpeg</small>
                      </div>
                    </div> */}
                  </div>
                  <div
                    style={{ display: "flex", gap: 8 }}
                    className="mt-2 mb-2 d-flex flex-column"
                  >
                    <div className="d-flex">
                      {i.image?.map((img, imgIndex) => (
                        <div key={imgIndex}>
                          <div className="custom-files m-2">
                            <img
                              src={
                                img.imageType !== "UPLOADED"
                                  ? `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.id}/${img.imageName}`
                                  : URL.createObjectURL(img.imageName)
                              }
                              alt={img.imageName}
                              height={90}
                              style={{ objectFit: "cover" }}
                              loading="lazy"
                            />
                            <div>
                              <label
                                className="custom-control-label cursor-pointer"
                                htmlFor={imgIndex}
                                onClick={() => {
                                  const updatedImages = [...i.image];
                                  updatedImages.splice(imgIndex, 1);
                                  setFieldValue(
                                    `specification[${index}].image`,
                                    updatedImages
                                  );
                                }}
                              >
                                <p className="text-danger text-center font-weight-bold">
                                  Remove
                                </p>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 d-block"
                        onClick={() => {
                          const fileInput = document.getElementById(
                            `image-${i.id}`
                          );
                          fileInput.click();
                        }}
                      >
                        Add Image{" "}
                        {console.log(values.specification[index].image)}
                      </button>
                      <input
                        type="file"
                        id={`image-${i.id}`}
                        accept="image/png,image/jpg,image/jpeg"
                        hidden
                        onChange={(e) => {
                          setFieldValue(`specification[${index}].image`, [
                            ...i.image,
                            {
                              id: i.image.length + 1,
                              imageName: e.target.files[0],
                              imageType: "UPLOADED",
                            },
                          ]);
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-4 mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input ml-2"
                      name="isReturnable"
                      id="isReturnable"
                      checked={i.returnable}
                      onClick={(e) => {
                        setFieldValue(`specification[${index}]`, {
                          ...i,
                          returnable: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="isReturnable" className="ml-2">
                      {" "}
                      Click If Product Is Returnable{" "}
                    </label>
                  </div>
                  {i.returnable === true && (
                    <div className="form-group row mt-4">
                      <label
                        htmlFor="returnDays"
                        className="col-md-2 col-form-label"
                      >
                        Returnable Days: <span className="text-danger">*</span>
                      </label>

                      <div className="col-md-6" id="select_div">
                        <input
                          className="form-control"
                          name="returnDays"
                          onChange={(e) =>
                            setFieldValue(`specification[${index}]`, {
                              ...i,
                              returnDays: e.target.value,
                            })
                          }
                          value={i.returnDays}
                          placeholder="Returnable Days"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="row">
                    <table className="table">
                      <thead className="table-primary">
                        <tr>
                          <th scope="col">Product Size</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">
                            Price{" "}
                            <span className="text-info">
                              (Per Quantity including 13% VAT)
                            </span>
                            <span className="ml-1"> * </span>
                          </th>
                          <th scope="col">Discount in %</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row">
                            <div className="mb-3">
                              <input
                                className="form-control"
                                id="inStock"
                                name={`productSize[${index}]`}
                                onChange={(e) => {
                                  setFieldValue(`specification[${index}]`, {
                                    ...i,
                                    productSize: e.target.value,
                                  });
                                }}
                                value={i.productSize}
                                placeholder="Size"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="mb-3">
                              <input
                                className="form-control"
                                id="productQuantity"
                                name={`productQuantity[${index}]`}
                                onChange={(e) =>
                                  setFieldValue(`specification[${index}]`, {
                                    ...i,
                                    productQuantity: e.target.value,
                                  })
                                }
                                value={i.productQuantity}
                                placeholder="quantity"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="mb-3">
                              <input
                                className="form-control"
                                id="productRate"
                                name={`productRate[${index}]`}
                                onChange={(e) =>
                                  setFieldValue(`specification[${index}]`, {
                                    ...i,
                                    productRate: e.target.value,
                                  })
                                }
                                value={i.productRate}
                                placeholder="Enter Price Inclusive of VAT @ 13%"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="mb-3">
                              <input
                                className="form-control"
                                id="productDiscount"
                                name={`productDiscount[${index}]`}
                                onChange={(e) =>
                                  setFieldValue(`specification[${index}]`, {
                                    ...i,
                                    productDiscount: e.target.value,
                                  })
                                }
                                value={i.productDiscount}
                                placeholder="Special Price"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="form-group row mt-4">
                      <div className="row mt-3">
                        <label
                          htmlFor="inputProduct"
                          className="col-md-2 col-form-label"
                        >
                          Package Weight (kg):{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-2 mt-2">
                          <input
                            name={`productWeight[${index}]`}
                            className="form-control"
                            id="productWeight"
                            value={i.product_weight}
                            onChange={(e) =>
                              setFieldValue(`specification[${index}]`, {
                                ...i,
                                product_weight: e.target.value,
                              })
                            }
                            placeholder="Package Weight (kg)"
                          />
                        </div>
                        <label
                          htmlFor="inputProduct"
                          className="col-md-2 mt-1 col-form-label"
                        >
                          Package Dimensions:{" "}
                          <span className="text-info">(Optional)</span>
                        </label>
                        <div className="col-md-2 mt-2">
                          <input
                            className="form-control"
                            name={`productLength[${index}]`}
                            value={i.productLength}
                            placeholder="Length (cm)"
                            onChange={(e) =>
                              setFieldValue(`specification[${index}]`, {
                                ...i,
                                productLength: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-2 mt-2">
                          <input
                            className="form-control"
                            name={`productThickness[${index}]`}
                            placeholder="Thickness (cm)"
                            value={i.productThickness}
                            onChange={(e) =>
                              setFieldValue(`specification[${index}]`, {
                                ...i,
                                productThickness: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-2 mt-2">
                          <input
                            className="form-control"
                            name={`productHeight[${index}]`}
                            onChange={(e) =>
                              setFieldValue(`specification[${index}]`, {
                                ...i,
                                productHeight: e.target.value,
                              })
                            }
                            value={i.productHeight}
                            placeholder="Height (cm)"
                          />
                        </div>
                      </div>
                      {(localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
                        wholesaler === true) && (
                        <>
                          <label
                            htmlFor="inputProduct"
                            className="col-md-2 col-form-label mt-4"
                          >
                            Minimum Quantity :{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-2 mt-4">
                            <input
                              name="minimumQuantity"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue(`specification[${index}]`, {
                                  ...i,
                                  minimumQuantity: e.target.value,
                                });

                                i.productQuantity < e.target.value
                                  ? setValidQuantity(true)
                                  : setValidQuantity(false);
                              }}
                              value={i.minimumQuantity}
                              placeholder="Minimum Quantity"
                            />
                            {validQuantity && (
                              <p style={{ color: "red" }}>
                                Minimum quantity must be less than quantity
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex-fill flex-fill">
            <button
              ref={buttonLoader}
              className="btn btn-lg btn-outline-info mt-2"
              id="psends"
              style={{ float: "right", textTransform: "capitalize" }}
            >
              Update Product
            </button>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <Spinner />
  );
};

export default EditProduct;
