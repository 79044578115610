import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Flag from "../../../assets/Images/flag.png";
import kreesaRealState from "../../../assets/Images/kreesa-real-state.png";
import kreesaServices from "../../../assets/Images/kreesa-services.png";
import userAppQr from "../../../assets/Images/user-app-qr.png";
import businessAppQr from "../../../assets/Images/wholesale-app-qr.png";

const UserFooter = () => {
  return (
    <footer className="text-center text-lg-start text-white user__footer">
      <FooterTopSection />
      {/*  */}
      <section
        className="d-flex justify-content-between p-1 py-5 f-section footer__linear__gradient"
        // style={{ backgroundColor: "#6351ce" }}
      >
        {/*  */}
        <div
          className="me-5 get-touch"
          style={{
            verticalAlign: "-webkit-baseline-middle",
            fontSize: "x-large",
            fontStyle: "italic",
            fontWeight: "bold",
          }}
        >
          <span className="text-capitalize px-2 get-in-touch">
            Get connected with us on social networks
          </span>
        </div>
        <div className="mr-2 __icons">
          <a href="https://www.facebook.com/adminkreesa" target="_blank">
            <i
              className="fab fa-facebook footer-social"
              style={{ fontSize: "20px" }}
            ></i>
          </a>
          <a href="https://twitter.com/kreesa_sales" target="_blank">
            <i
              style={{ fontSize: "20px" }}
              className="fab fa-twitter footer-social"
            ></i>
          </a>
          <a href="https://www.instagram.com/kreesa_com" target="_blank">
            <i
              style={{ fontSize: "20px" }}
              className="fab fa-instagram  footer-social"
            ></i>
          </a>
          <a href="viber://chat?number=+9779813007298" target="_blank">
            <i
              style={{ fontSize: "20px" }}
              className="fab fa-viber  footer-social"
            ></i>
          </a>
          <a
            // href="https://web.whatsapp.com/send?phone=+9779813007298"
            href="https://wa.me/+9779813007298"
            target="_blank"
          >
            <i
              style={{ fontSize: "20px" }}
              className="fab fa-whatsapp  footer-social"
            ></i>
          </a>
          {/* <a href="https://vm.tiktok.com/ZSJ7TfWA4" target="_blank">
                <i style={{fontSize:"20px",color:"red"}} className="fab fa-tiktok fabs"></i>
              </a> */}
        </div>
      </section>

      <section
        className="d-flex justify-content-between p-1 py-5 f-section footer__linear__gradient"
        // style={{ backgroundColor: "#6351ce" }}
      >
        {/*  */}
        <div
          className="me-5 get-touch"
          style={{
            verticalAlign: "-webkit-baseline-middle",
            fontSize: "x-large",
            fontStyle: "italic",
            fontWeight: "bold",
          }}
        >
          <span className="text-capitalize px-2 get-in-touch">
            Download our Kreesa Online Shopping App on PlayStore
          </span>
        </div>
        <div className="mr-2 __icons"  style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
          <a href="#" style={{ marginRight: 40 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={userAppQr}
                alt="User App Qr"
                style={{ height: 120, width: 120, objectFit: "cover" }}
              />
              <p style={{ fontWeight: "bold", color: "#ffffff",marginTop: 10 }}>User App</p>
            </div>
          </a>
          <a href="#" style={{ marginRight: 40 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={businessAppQr}
                alt="Business App Qr"
                style={{ height: 120, width: 120, objectFit: "cover" }}
              />
              <p style={{ fontWeight: "bold", color: "#ffffff", marginTop: 10 }}>Business App</p>
            </div>
          </a>
        </div>
      </section>

      <section>
        <div className="container-fluid text-center text-md-start mt-5">
          <div className="row">
            <div className="col-md-3 mt-4 col-lg-4 col-xl-3 mx-auto mb-4 footer-heading">
              <h6 className="text-uppercase  ml-2 ">PAYMENT & SHIPPING</h6>
              <hr className="mb-4 mt-0 mx-auto" />
              <p className="fp">
                <a href="/terms-of-use" className="anchor-hover">
                  Terms of Use
                </a>
              </p>
              <p>
                <a href="/payment-methods" className="anchor-hover">
                  Payment Methods
                </a>
              </p>
              <p>
                <a href="/shipping-guide" className="anchor-hover">
                  Shipping Guide
                </a>
              </p>
              {/* <p>
                <a href="#!" className="anchor-hover">
                  Locations We Ship To
                </a>
              </p> */}
              {/* <p>
                <a href="#!" className="anchor-hover">
                  Selling Guide
                </a>
              </p> */}
              <p>
                <a href="/seller-page" className="anchor-hover">
                  Seller Page
                </a>
              </p>
              <p>
                <a href="/selling-guide" className="anchor-hover">
                  Online Selling Guide
                </a>
              </p>
            </div>

            <div className="col-md-2 mt-4 col-lg-2 col-xl-2 mx-auto mb-4 footer-heading">
              <h6 className="text-uppercase  ml-2 ">CUSTOMER SERVICE</h6>
              <hr className="mb-4 mt-0 mx-auto" />
              {/* <p>
                <a href="/shipping-policy" className="anchor-hover">
                  Shipping Policy
                </a>
              </p> */}
              <p>
                <Link to="/jobs" className="anchor-hover">
                  Career
                </Link>
              </p>
              {localStorage.getItem("auth") === "ROLE_USER" && (
                <p>
                  <a href="/manage-account?my-profile" className="anchor-hover">
                    My Account
                  </a>
                </p>
              )}
              <p>
                <a href="/return-policy" className="anchor-hover">
                  Return Policy
                </a>
              </p>
              <p>
                <a href="/contact" className="anchor-hover">
                  Contact Us
                </a>
              </p>
              <p>
                <Link to="/kreesa-teaches" className="anchor-hover">
                  Kreesa Teaches (FAQ)
                </Link>
              </p>

              {/* <p>
                <a href="#!" className="anchor-hover">
                  Site Map
                </a>
              </p> */}
            </div>
            <div className="col-md-3 mt-4 col-lg-2 col-xl-2 mx-auto mb-4 footer-heading">
              <h6 className="text-uppercase ml-2 ">ABOUT MARKET</h6>
              <hr className="mb-4 mt-0 mx-auto" />
              <p>
                <Link to="/about" className="anchor-hover">
                  About Us
                </Link>
              </p>
              <p>
                <a href="/complaint" className="anchor-hover">
                  Kreesa Complaint
                </a>
              </p>
              <p>
                <Link
                  to="/termsandconditions"
                  target="_blank"
                  className="anchor-hover"
                >
                  Terms and Conditions
                </Link>
              </p>
              <p>
                <a href="/privacy-policy" className="anchor-hover">
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="col-md-4 mt-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footer-heading">
              <h6 className="text-uppercase  ml-2 ">Contact</h6>
              <hr className="mb-4 mt-0 mx-auto" />
              <a
                href="https://www.google.com/maps/place/Kreesa+Sales+Private+Limited/@27.720575,85.281184,14z/data=!4m5!3m4!1s0x0:0x1a0a0e1dfdbd435f!8m2!3d27.7205751!4d85.2811841?hl=en"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <p className="text-justify">
                  <i className="fas fa-home mr-3"></i>
                  Head Office: Diamondmarg 3, Panchdhara, Kathmandu 44620
                </p>
              </a>

              <p className="text-justify">
                <i className="fas fa-envelope mr-3"></i>{" "}
                <a href="mailto:kreesasales@gmail.com">
                  {" "}
                  kreesasales@gmail.com{" "}
                </a>
              </p>
              <p className="text-justify">
                <i className="fas fa-phone mr-3"></i>
                <a href="tel:+977 1-5249582"> +977 1-5249582,</a>
                <a href="tel:9851325737"> 9851325737,</a>
                <a href="tel:9813007298"> 9813007298</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © {new Date().getFullYear()}{" "}
        <img
          src={Flag}
          alt="flag"
          style={{
            height: 20,
            width: 20,
          }}
        />{" "}
        Made in Nepal | Copyright:
        <Link className="f-anchor" to="/">
          Kreesa
        </Link>
      </div>
    </footer>
  );
};

export default UserFooter;

export const FooterTopSection = () => (
  <section
    className="d-flex justify-content-between p-1 py-5 f-section"
    style={{ backgroundColor: "rgb(208 207 213)", alignItems: "center" }}
  >
    {/*  */}
    <div
      className="me-5 get-touch"
      style={{
        verticalAlign: "-webkit-baseline-middle",
        fontSize: "x-large",
        fontStyle: "italic",
        fontWeight: "bold",
      }}
    >
      <span
        className="text-capitalize px-2 get-in-touch"
        style={{ color: "black" }}
      >
        Our Other Business
      </span>
    </div>
    <div className="mr-6 __icons" style={{ display: "flex" }}>
      <a
        href="http://realestate.kreesa.com/"
        target="_blank"
        style={{ marginRight: 40 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={kreesaRealState}
            alt="kreesaRealState"
            style={{ height: 50, width: 50, objectFit: "cover" }}
          />
          <p style={{ fontWeight: "bold" }}>Kreesa Realestate</p>
        </div>
      </a>
      <a href="https://www.kreesasewa.com" target="_blank">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={kreesaServices}
            alt="kreesaServices"
            style={{ height: 50, width: 50, objectFit: "cover" }}
          />
          <p style={{ fontWeight: "bold" }}>Kreesa Services</p>
        </div>
      </a>
    </div>
  </section>
);
