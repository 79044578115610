import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import HtmlParser from "react-html-parser";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Spinner from "../../Spinner";
import { Excerpt } from "../../../../utils";

const ViewBrands = (props) => {
  const history = useHistory();
  const [state, setState] = useState([]);

  // _______Modal_________//
  const [modalId, setModalId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => setModalId("");

  const fetchAllBrand = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const brandRes = await AxiosConfig.get(
        `/kreesa/admin/brand/get-all-brand`,
        config
      );
      setState(brandRes.data);
      setLoading(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setLoading(false);
    }
  };
  /* ----------------------------------- deleteBanner ---------------------------------- */
  const deleteBanner = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      // const type = "customer";
      await AxiosConfig.delete(`/kreesa/admin/brand/delete-brand/${id}`, config);
      // setState(getCustomerDetails.data);
      await fetchAllBrand();
    } catch (err) {
      console.log(err);
    }
  };
  /* ----------------------------------- deleteBanner ---------------------------------- */

  useEffect(() => {
    fetchAllBrand();
  }, []);

  return !loading ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <div
                onClick={() => history.push("/admin/add-brand")}
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add Brand <i className="fab fa-bandcamp ml-2"></i>
              </div>
              {/* <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search User Banner…"
                    aria-label="Search"
                    // ref={inputRef} onChange={filterCategory}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Customers Banners </h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Brand</th>
                  <th>Brand Name</th>
                  <th>Description</th>
                  <th>Creation Date</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {state?.map((p, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div className="cursor-pointer">
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/brand/${p.id}/${p.image}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2 "
                          alt="Banner"
                        />
                      </div>
                    </td>
                    <td>
                      <span className={`p-2`}>{p.brandName}</span>
                    </td>
                    <td>{HtmlParser(Excerpt(p.description, 40))}</td>

                    <td>{moment(p.creationDate).format("ll")}</td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-left">
                          <Link
                            className="dropdown-item text-info"
                            to={`/admin/edit-brand/${p.id}`}
                          >
                            <i className="fas fa-user-edit"></i> Edit
                          </Link>
                          <div className="dropdown-divider"></div>
                          <button
                            onClick={() => setModalId(`modal${i}`)}
                            className="dropdown-item text-success"
                          >
                            <i className="fas fa-eye"></i> Quick View
                          </button>
                          <div className="dropdown-divider"></div>
                          <button
                            className="dropdown-item text-danger"
                            onClick={() => deleteBanner(p.id)}
                          >
                            <i className="fas fa-trash"></i> Delete
                          </button>
                          <Modal
                            show={modalId === `modal${i}`}
                            onHide={handleClose}
                            {...props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Brand Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="container">
                                <div className="row">
                                  <div className="col">
                                    <img
                                      className="img-fluid img-thumbnail"
                                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/brand/${p.id}/${p.image}`}
                                      height={650}
                                      width={700}
                                      alt="brand"
                                    />
                                  </div>
                                  <div className="col">
                                    <h4>Description</h4>
                                    {HtmlParser(p.description)}
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ViewBrands;
